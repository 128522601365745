import cn from 'classnames';
import { FC, useEffect, useState } from 'react';

import { Image, Video } from '@commerce/types/common';
import { Renderer } from '@components/screen/factory';
import Button from '@components/ui/Button/Button';
import Container from '@components/ui/Container';
import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';
import Text from '@components/ui/Text/Text';
import { isVideo } from '@lib/video';

import style from './Interactive.module.scss';

interface Item {
  content: {
    header: string;
    itemDescription: string;
    icon?: Image;
    media: Image | Video;
  };
}

interface InteractiveProps {
  header: string;
  description: string;
  cta: string;
  ctaUrl: string;
  itemAlignment: 'right' | 'left';
  items: Item[];
  intervalDuration?: number;
  transitionDuration?: number;
}

const Interactive: FC<InteractiveProps> = ({
  header,
  description,
  cta,
  ctaUrl,
  itemAlignment,
  items,
  intervalDuration = 3000,
  transitionDuration = 500,
}) => {
  const [activeItem, setActiveItem] = useState<number>(0);
  const [transitioning, setTransitioning] = useState<boolean>(false);
  const [isAutoplayPaused, setIsAutoplayPaused] = useState<boolean>(false);

  useEffect(() => {
    if (isAutoplayPaused || !items || items.length === 0) {
      return; // Exit early if autoplay is paused or items is not available or empty
    }

    const interval = setInterval(() => {
      setTransitioning(true);
      setTimeout(() => {
        setActiveItem((prevItem) => (prevItem + 1) % items.length);
        setTransitioning(false);
      }, transitionDuration);
    }, intervalDuration);

    // Clean up interval on component unmount
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [items, isAutoplayPaused, intervalDuration, transitionDuration]);

  const handleItemClick = (index: number) => {
    if (activeItem !== index) {
      setTransitioning(true);
      setTimeout(() => {
        setActiveItem(index);
        setTransitioning(false);
      }, transitionDuration);
    }
  };

  const handleMouseToggle = (isPaused: boolean) => {
    setIsAutoplayPaused(isPaused);
  };

  const columnOrderClasses = !itemAlignment ? 'flex-col-reverse md:flex-row-reverse' : 'flex-col-reverse md:flex-row';

  return (
    <Container className={cn(style.container, 'py-4 px-6')}>
      <div
        className="flex w-full p-4 md:p-0 md:w-2/3 flex-col space-y-4"
        onMouseEnter={() => handleMouseToggle(true)}
        onMouseLeave={() => handleMouseToggle(false)}
      >
        {header && (
          <Text variant="header-2" asElement="h2" weight="bold" className={style.header}>
            {header}
          </Text>
        )}
        {description && (
          <Text variant="header-4" asElement="p" weight="normal" className={style.description}>
            {description}
          </Text>
        )}

        {cta && ctaUrl && (
          <Button href={ctaUrl} width={160} type="button" size="md" variant="cta" className={style.cta}>
            {cta}
          </Button>
        )}
      </div>

      {items && items.length > 0 && (
        <div className={`mt-11 w-full flex items-start ${columnOrderClasses}`}>
          {/* Left Column */}
          <div
            className={`${style.textContainer} w-full md:w-1/2 ${
              !itemAlignment ? 'lg:pl-28' : 'lg:pr-28'
            } flex flex-col`}
            onMouseEnter={() => handleMouseToggle(true)}
            onMouseLeave={() => handleMouseToggle(false)}
          >
            {items.map((item, index) => {
              const { header: itemHeader, itemDescription, icon } = item.content;
              const isActive = activeItem === index;

              return (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  key={itemHeader}
                  className={cn(
                    style.collapse,
                    `flex flex-col cursor-pointer overflow-hidden transition-all duration-300 ease-in-out p-2 md:p-6 rounded-2xl`,
                    {
                      [style.active]: isActive,
                    }
                  )}
                  onClick={() => handleItemClick(index)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleItemClick(index);
                    }
                  }}
                >
                  <div className={`flex items-center px-4 ${isActive ? 'mb-2 md:mb-4' : ''}`}>
                    {icon && <img src={icon.url} alt={itemHeader} className="w-6 h-6 mr-2" />}
                    {itemHeader && (
                      <Text className="font-semibold" variant="header-5" asElement="h3">
                        {itemHeader}
                      </Text>
                    )}
                  </div>
                  <div
                    className={`transition-max-height duration-300 ease-in-out ${
                      isActive ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'
                    }`}
                  >
                    {itemDescription && (
                      <Text
                        variant="base"
                        className={cn(style.itemDescription, 'p-4')}
                        html={itemDescription}
                        asElement="div"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Right Column */}
          <div
            className="w-full md:w-1/2 flex items-center justify-center p-4 lg:p-0"
            onMouseEnter={() => handleMouseToggle(true)}
            onMouseLeave={() => handleMouseToggle(false)}
          >
            <div
              className={cn(
                `transition-opacity duration-500 ease-in-out`,
                {
                  'opacity-0': transitioning,
                  'opacity-100': !transitioning,
                },
                style.mediaContainer
              )}
            >
              {items[activeItem]?.content.media && (
                <ImageOrVideo
                  type={
                    isVideo(items[activeItem]?.content.media.type)
                      ? (items[activeItem]?.content.media.type as string)
                      : 'image'
                  }
                  {...items[activeItem]?.content.media}
                  className="w-full h-full object-cover"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Renderer({ name: 'moduleInteractive' })(Interactive);
