import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Video as VideoProps } from '@commerce/types/common';
import { renderImage } from '@lib/image';

import style from './video.module.scss';

interface Props extends VideoProps {
  className?: string;
  renderAsThumbnail?: boolean;
  controls?: boolean;
  playOnActive?: boolean;
  preload?: string;
}

const Video: FC<Props> = ({
  url,
  thumbnail,
  type,
  className,
  renderAsThumbnail = false,
  controls = true,
  playOnActive = false,
  preload = 'metadata',
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { t } = useTranslation(['common']);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (renderAsThumbnail && playOnActive) {
      videoRef?.current?.play();
    } else {
      videoRef?.current?.pause();
    }
  }, [playOnActive, renderAsThumbnail]);

  const handlePlayPause = useCallback(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  }, [isPlaying]);

  return (
    <div className={cn('relative flex h-full w-full items-center', className)}>
      <video
        poster={thumbnail}
        className={cn(className, 'w-full cursor-pointer')}
        ref={videoRef}
        controls={renderAsThumbnail ? false : controls}
        muted
        playsInline
        preload={preload}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        {...(!renderAsThumbnail ? { onClick: handlePlayPause } : undefined)}
      >
        {/* always show the first frame */}
        <source src={`${url}#t=0.1`} type={type} />
        <track src={url} kind="captions" label="english" />
      </video>

      {!renderAsThumbnail && (
        <div className={style.buttonContainer}>
          <button
            type="button"
            aria-label="play/pause video"
            className={cn(style.button, isPlaying && style.paused)}
            onClick={handlePlayPause}
          >
            {renderImage(
              { url: '/svgs/play-button.svg', alt: t('common:playButton') },
              { 'aria-hidden': true, loading: 'lazy', width: 100, height: 100 }
            )}
          </button>
        </div>
      )}

      {renderAsThumbnail && (
        <>
          {renderImage(
            { url: '/svgs/play-button.svg', alt: t('common:playButton') },
            { 'aria-hidden': true, loading: 'lazy', className: style.thumbnailOverlay, width: 100, height: 100 }
          )}
        </>
      )}
    </div>
  );
};

export function renderVideo(video: Props) {
  if (!video?.url) {
    return null;
  }
  return <Video {...video} />;
}

export function isVideo(type?: string): boolean {
  return !!type?.toLowerCase().includes('video');
}
