import { useTranslation } from 'next-i18next';
import { FC } from 'react';

type Props = { options: string[]; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void };

const PlanToggle: FC<Props> = ({ options, onChange }) => {
  const { t } = useTranslation(['plans']);

  return (
    <label
      className="relative flex flex-row justify-between min-w-[220px] h-[44px] rounded-full cursor-pointer items-center bg-violet mb-6 px-1 text-white text-base leading-7"
      title={t('plans:choosePlans.planToggle.label.title')}
      htmlFor="checkbox"
    >
      <input id="checkbox" type="checkbox" onChange={onChange} className="hidden peer" />
      <span className="py-1 px-6 h-[36px] rounded-full peer-[:not(:checked)]:bg-purple peer-[:not(:checked)]:shadow-[0_0_6px_-2px_rgba(36,0,71,1)]">
        {options[1]}
      </span>
      <span className="py-1 px-6  h-[36px] rounded-full peer-checked:bg-purple peer-checked:shadow-[0_0_6px_-2px_rgba(36,0,71,1)]">
        {options[0]}
      </span>
    </label>
  );
};

export default PlanToggle;
