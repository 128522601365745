import cn from 'classnames';

import {
  FeaturedTriBulletsContainer,
  FeaturedTriBulletsContainerContent,
} from '@components/common/types/FeaturedTriBulletsContainer';
import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Container from '@components/ui/Container';
import Text from '@components/ui/Text/Text';
import { getContentfulImgUrl, renderImage } from '@lib/image';

import s from './FeatureTriBullets.module.scss';

interface Props extends FeaturedTriBulletsContainer {
  className?: string;
}

interface RendererProps extends FeaturedTriBulletsContainerContent {
  className?: string;
}

const Feature = ({ title, description, moduleFeaturedTriBullets: features }: FeaturedTriBulletsContainer) => (
  <Container className="px-6 py-15">
    <div className={cn('content', s.wrapper)}>
      <Text variant="heading-1" className={s.title} asElement="h2">
        {title}
      </Text>
      <Text variant="text-2" html={description} className={s.description} />
    </div>
    <div className={s.featureWrapper}>
      {features.map(({ internalName, headLine, icon, description: featureDescription }, ind: number) => (
        <div key={internalName} className="basis-full">
          {headLine && (
            <Text variant="heading-3" asElement="h3" color="var(--grey-800)">
              {headLine}
            </Text>
          )}
          {icon?.url
            ? renderImage(
                { url: getContentfulImgUrl(icon.url, 100), alt: icon.alt || internalName },
                { className: s.icon }
              )
            : null}
          <Text
            variant="text-2"
            color="var(--text-secondary-2)"
            html={featureDescription}
            className={cn(s.description, { [s.lastChild]: ind === features.length - 1 })}
          />
        </div>
      ))}
    </div>
  </Container>
);
const mapper = ({ moduleFeaturedTriBullets, ...rest }: RendererProps): Props => ({
  ...rest,
  moduleFeaturedTriBullets: mapContent(moduleFeaturedTriBullets),
});

export default Renderer({ name: 'moduleFeaturedTriBulletsContainer', mapper })(Feature);
