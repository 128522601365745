import cn from 'classnames';
import { FC, useState } from 'react';
import { FreeMode, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ModulePlansAndPricing as Props } from '@components/common/types/ModulePlansAndPricing';
import { Renderer } from '@components/screen/factory';
import Container from '@components/ui/Container';
import Text from '@components/ui/Text/Text';
import getTailwindConfig from '@lib/get-tailwind-config';
import { useMatchMedia } from '@lib/hooks/useMatchMedia';
import { renderImage } from '@lib/image';

import PlanCard, { MembershipPeriodOptions } from './PlanCard';
import PlanToggle from './PlanToggle';

import style from './PlansAndPricing.module.scss';

const PlansAndPricing: FC<Props> = ({ period, hint, membershipPlan }) => {
  const [membershipPeriod, setMembershipPeriod] = useState(MembershipPeriodOptions.Yearly);
  const lg = getTailwindConfig('theme.screens.lg');
  const isMobileDevice = useMatchMedia(lg);
  const slideWithBadge = membershipPlan.findIndex((plan) => plan.content.badge !== undefined);
  const initialSlideIndex = slideWithBadge >= 0 ? slideWithBadge : 0;

  const planToggleListener = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setMembershipPeriod(MembershipPeriodOptions.Monthly);
    } else {
      setMembershipPeriod(MembershipPeriodOptions.Yearly);
    }
  };
  // hint = undefined;

  return (
    <Container className="flex flex-col items-center">
      <PlanToggle options={period} onChange={planToggleListener} />
      {hint && (
        <div className="w-60 px-5 mb-12 text-xs flex flex-row items-center">
          {renderImage(
            { url: '/svgs/arrow-pointer-up.svg', alt: 'Arrow up' },
            { width: 48, height: 48, loading: 'lazy' }
          )}
          <Text asElement="span" html={hint} className="text-xs" />
        </div>
      )}
      {isMobileDevice ? (
        <Swiper
          className="py-10 w-full"
          modules={[FreeMode, Pagination]}
          setWrapperSize
          freeMode
          centeredSlides
          slidesPerView={1.5}
          spaceBetween={30}
          initialSlide={initialSlideIndex}
          pagination={{
            clickable: true,
            bulletClass: style.swiperPaginationBullet,
            bulletActiveClass: style.activeSwiperPaginationBullet,
            el: `.${style.swiperPagination}`,
          }}
          breakpoints={{
            480: {
              slidesPerView: 1.7,
            },
            540: {
              slidesPerView: 1.85,
            },
            640: {
              slidesPerView: 1.95,
            },
            720: {
              slidesPerView: 2,
            },
          }}
        >
          {membershipPlan.map(({ content, id }, i) => (
            <SwiperSlide key={`${id + i}`} className="h-auto">
              <PlanCard {...content} period={membershipPeriod} className="h-full m-auto" />
            </SwiperSlide>
          ))}

          <div
            className={cn(
              style.swiperPagination,
              membershipPlan.length <= 2 ? style.swiperPaginationTooFewSlides : '',
              'featured-slide-pagination'
            )}
          />
        </Swiper>
      ) : (
        <div className="flex flex-row">
          {membershipPlan.map(({ content, id }, i) => (
            <PlanCard key={`${id + i}`} {...content} period={membershipPeriod} className="w-[250px] mx-4" />
          ))}
        </div>
      )}
    </Container>
  );
};

export default Renderer({ name: 'modulePlansAndPricing' })(PlansAndPricing);
