import { documentToReactComponents, NodeRenderer, Options, RenderText } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import { escapeHtml, getUrlForFile } from '@services/contentful/helpers/rich-text';
import { Entry } from 'contentful';

import { resolveRichTextRenderer } from '@components/common/RichText/factory';
import { getTextComponentWithBreakLines } from '@components/common/RichText/helpers';

function renderComponent(path: string, props: any) {
  const rendererConfig = resolveRichTextRenderer(path);
  const { component: Component, mapper } = rendererConfig ?? {};
  return Component && <Component {...(mapper?.(props) ?? props)} />;
}

const renderEntry: NodeRenderer = (node) => {
  const entry: Entry<any> = node.data.target;
  return renderComponent(entry.sys.contentType.sys.id, entry);
};

const renderAsset: NodeRenderer = (node) => {
  const { title, description } = node.data.target.fields;
  const url = getUrlForFile(node.data.target.fields.file);
  const escapedText = escapeHtml(description || title);
  return <img src={url} alt={escapedText} title={escapedText} />;
};

const renderText: RenderText = (text) =>
  text.split('"').flatMap((txt, i) => {
    // Convert newlines to <br>s
    const Component = getTextComponentWithBreakLines(txt);

    // eslint-disable-next-line react/no-array-index-key
    return [i % 2 === 0 ? Component : <q key={i}>{Component}</q>];
  });

const renderOptions: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: renderEntry,
    [BLOCKS.EMBEDDED_ASSET]: renderAsset,
    [INLINES.HYPERLINK]: (node) => renderComponent(INLINES.HYPERLINK, node),
  },
  renderText,
  // Use ada-friendly tags for bold and italic
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
};

export function renderEmbeddedEntries(document: Document) {
  return documentToReactComponents(document, renderOptions);
}
