import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import style from './Accordion.module.scss';

export interface Props {
  header: string | ReactNode;
  separator?: boolean;
  collapsed?: boolean;
  setCollapsed?: (collapsed: boolean) => void;
  className?: string;
  headerClassName?: string;
  children: ReactNode;
  animation?: 'slide' | 'fade';
  // flag whether to remove component when `collapsed` is true
  removeOnCollapsed?: boolean;
  id?: string;
}

const Accordion: FC<Props> = ({
  header,
  separator = false,
  className,
  headerClassName,
  children,
  collapsed: defaultCollapsed = false,
  setCollapsed: defaultSetCollapsed,
  animation = 'slide',
  removeOnCollapsed = true,
  id,
}) => {
  const [localCollapsed, setLocalCollapsed] = useState(defaultCollapsed);
  const { t } = useTranslation('product');

  useEffect(() => {
    setLocalCollapsed(defaultCollapsed);
  }, [defaultCollapsed]);

  const { collapsed, setCollapsed } = defaultSetCollapsed
    ? { collapsed: defaultCollapsed, setCollapsed: defaultSetCollapsed }
    : { collapsed: localCollapsed, setCollapsed: setLocalCollapsed };

  const motionProps = useMemo(() => {
    let open;
    let closed;
    let transition;
    let exitTransition;
    const duration = 0.25;
    const ease = 'easeOut';

    switch (animation) {
      case 'fade': {
        open = { opacity: 1 };
        closed = { opacity: 0 };
        transition = { opacity: { duration } };
        exitTransition = { opacity: { duration: 0 } };
        break;
      }
      case 'slide':
      default: {
        open = { height: 'auto', opacity: 1 };
        closed = { height: 0, opacity: 0 };
        transition = { height: { duration }, opacity: { duration: duration * 0.75, delay: duration * 0.3 } };
        exitTransition = { ...transition, opacity: { delay: duration * 0.3 } };
        break;
      }
    }

    return {
      initial: closed,
      animate: {
        ...open,
        ease,
        transition,
      },
      exit: {
        ...closed,
        ease,
        transition: exitTransition || transition,
      },
    };
  }, [animation]);

  const renderHeader = () => {
    if (typeof header === 'string') {
      return <span>{header}</span>;
    }
    return header;
  };

  return (
    <div className={cn(style.container, className)} data-testid="accordion" id={id}>
      <button className={cn(style.header, headerClassName)} type="button" onClick={() => setCollapsed(!collapsed)}>
        {renderHeader()}
        <FontAwesomeIcon
          className={cn('text-secondary', style.icon, { [style.open]: !collapsed })}
          icon={['fal', 'chevron-down']}
          size="sm"
          title={t(`filter.${!collapsed ? 'collapse' : 'expand'}`)}
          aria-hidden
          focusable={false}
        />
      </button>
      {separator && <hr className={style.separator} />}
      {removeOnCollapsed && (
        <AnimatePresence initial={false}>
          {!collapsed && (
            <motion.div
              key="accordion-content"
              style={{ overflow: 'hidden' }}
              initial={motionProps.initial}
              animate={motionProps.animate}
              exit={motionProps.exit}
              data-testid="accordion-content"
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      )}
      {!removeOnCollapsed && (
        <motion.div
          key="accordion-content"
          style={{ overflow: 'hidden' }}
          initial={motionProps.initial}
          animate={collapsed ? motionProps.exit : motionProps.animate}
          exit={motionProps.exit}
          data-testid="accordion-content"
        >
          {children}
        </motion.div>
      )}
    </div>
  );
};

export default Accordion;
