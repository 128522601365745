import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';
import Link from '@components/ui/Link';
import { trackNavBarEvent } from '@lib/gtag';
import { isLife360Enabled } from '@lib/storefront';

type Props = { className?: string; onClick?: () => void };

export const LogoButton: FC<Props> = ({ className, onClick }) => {
  const { t } = useTranslation('common');
  const logoAlt = t('common:navigation.logoAlt');
  const desktopLogoUrl = isLife360Enabled() ? '/svgs/life360-logo-dark.svg' : '/svgs/life360-tile-logo.svg';
  const mobileLogoUrl = isLife360Enabled() ? '/svgs/life360-logo-dark.svg' : '/svgs/life360-tile-logo-mobile.svg';

  return (
    <Link
      data-testid="logo-home-link"
      href="/"
      title="home"
      onClick={() => {
        trackNavBarEvent('home');
        onClick?.();
      }}
      className={className}
    >
      <span className="sr-only">Homepage</span>
      <ImageOrVideo
        srcSet={{
          mobile: {
            url: mobileLogoUrl,
            type: 'image',
            alt: logoAlt,
          },
          desktop: {
            url: desktopLogoUrl,
            type: 'image',
            alt: logoAlt,
          },
        }}
      />
    </Link>
  );
};
