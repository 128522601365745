import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, FocusEventHandler, MouseEventHandler, useState } from 'react';

import I18nSelector from '@components/common/I18nSelector/I18nSelector';
import Searchbar from '@components/common/Searchbar/Searchbar';
import { AuthButton } from '@components/navigation/buttons/AuthButton';
import { CartButton } from '@components/navigation/buttons/CartButton';
import { HelpButton } from '@components/navigation/buttons/HelpButton';
import { LogoButton } from '@components/navigation/buttons/LogoButton';
import Button from '@components/ui/Button/Button';
import Container from '@components/ui/Container';
import Link from '@components/ui/Link';
import { isLife360Enabled, isStorefrontDisabled } from '@lib/storefront';

import { ClickableProps, NavigationMenu, NavigationProps } from '../types';
import { MenuCard } from './MenuCard';
import { StandaloneLink } from './StandaloneLink';
import { Submenu } from './Submenu';

import s from './DesktopNavigation.module.scss';

const Menu: FC<ClickableProps<NavigationMenu>> = ({ links = [], submenus = [], standaloneLink, onClick }) => {
  const hideSubMenus = isLife360Enabled() && submenus.length === 0;
  return (
    <div className={s.menu}>
      <Container>
        <div
          className={cn({
            [s.containerNotFull]: links.length < 4,
          })}
        >
          <nav className="border-b pb-4 flex flex-row flex-nowrap space-x-8">
            {links.map((link, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuCard key={`${index}${link.id}`} {...link.content} onClick={onClick} totalCount={links.length} />
            ))}
          </nav>
          {!hideSubMenus && (
            <div className="pt-4 flex flex-row justify-between min-h-[85px]">
              <div className="flex flex-row space-x-10">
                {submenus.map((submenu, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Submenu key={`${index}${submenu.id}`} {...submenu.content} onClick={onClick} />
                ))}
              </div>
              {standaloneLink && <StandaloneLink {...standaloneLink.content} onClick={onClick} />}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

const MenuButton: FC<
  ClickableProps<NavigationMenu> & {
    activeMenu?: string;
    open: (key: string) => void;
    close: () => void;
  }
> = (props) => {
  const { title, activeMenu, open, close, onClick } = props;
  const isOpen = activeMenu === title;

  const handleOpen = () => {
    open(title);
  };

  const handleMouseEnter: MouseEventHandler = (e) => {
    e.preventDefault();
    handleOpen();
  };

  const handleFocus: FocusEventHandler = (e) => {
    e.preventDefault();
    handleOpen();
  };

  return (
    <div
      className={cn(s.link, {
        [s.active]: isOpen,
      })}
    >
      <button
        type="button"
        onMouseLeave={close}
        className={cn(s.overlayTrigger, 'transition duration-150 ease-linear')}
        onFocus={handleFocus}
      >
        <div className="flex flex-row items-center">
          <span className="whitespace-nowrap" onMouseEnter={handleMouseEnter} onFocus={handleFocus}>
            {title}
          </span>
          <FontAwesomeIcon icon={faChevronDown} className="ml-2" aria-hidden />
        </div>
        <Menu {...props} onClick={onClick} />
      </button>
    </div>
  );
};

function NavbarRight({ setIsSearchFocused }: { setIsSearchFocused: (value: boolean) => void }) {
  const hideStorefront = isStorefrontDisabled();
  const showLife360 = isLife360Enabled();
  const { t } = useTranslation(['common', 'auth']);

  if (showLife360) {
    return (
      <div className={s.extraLinks}>
        <div className="flex flex-row space-x-4">
          <Button href="/pricing" variant="cta">
            {t('auth:action.getStarted')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      {hideStorefront ? (
        <div className={s.extraLinks} />
      ) : (
        <div className={s.extraLinks}>
          <div className={s.searchBar}>
            <Searchbar onBlur={() => setIsSearchFocused(false)} onFocus={() => setIsSearchFocused(true)} />
          </div>

          <div className={s.cartButton}>
            <CartButton />
          </div>
        </div>
      )}
    </>
  );
}

export const DesktopNavigation: FC<{ linksOrMenus: NavigationProps['linksOrMenus'] }> = ({ linksOrMenus = [] }) => {
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [activeMenu, setActiveMenu] = useState<string | undefined>();
  const close = () => setActiveMenu(undefined);
  const open = (title: string) => setActiveMenu(title);

  return (
    <header className="hidden lg:block bg-white relative">
      <nav className="border-b-[0.5px] border-[#111111]">
        <Container className="relative flex flex-row justify-end space-x-5 px-6 py-[10.5px]">
          <HelpButton />
          <I18nSelector />
          <AuthButton />
        </Container>
      </nav>
      <Container className={cn(s.mainNavigation, { [s.searchFocused]: isSearchFocused }, 'px-6')}>
        <LogoButton className={cn(isLife360Enabled() ? s.life360Logo : s.tileLogo, 'relative w-52 self-center')} />
        <nav className={s.mainLinks}>
          {linksOrMenus.map((item, index) =>
            item.contentType === 'navigationMenu' ? (
              <MenuButton
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}${item.id}`}
                {...item.content}
                activeMenu={activeMenu}
                open={open}
                close={close}
                onClick={close}
              />
            ) : (
              <Link
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}${item.id}`}
                href={item.content.url}
                className={cn(s.link, s.linkPadding, {
                  [s[item.content.theme?.toLocaleLowerCase() ?? '']]: !!item.content.theme,
                })}
                onFocus={close}
              >
                {item.content.title}
              </Link>
            )
          )}
        </nav>

        <NavbarRight setIsSearchFocused={setIsSearchFocused} />
      </Container>
    </header>
  );
};
