import cn from 'classnames';
import { FC, memo } from 'react';

import { ModulePlansAndPricingMembershipPlan } from '@components/common/types/ModulePlansAndPricing';
import Button from '@components/ui/Button/Button';
import Text from '@components/ui/Text/Text';
import { renderImage } from '@lib/image';

import style from './PlansAndPricing.module.scss';

export enum MembershipPeriodOptions {
  Yearly = 'Yearly',
  Monthly = 'Monthly',
}

type Props = Omit<ModulePlansAndPricingMembershipPlan, 'internalName'> & {
  period: MembershipPeriodOptions;
  className: string;
};

const PlanCard: FC<Props> = ({
  badge,
  heading,
  monthlyPrice,
  yearlyPrice,
  benefitsTitle,
  benefitsList,
  additionalOfferImage,
  additionalOfferText,
  ctaText,
  annualCtaLink,
  monthlyCtaLink,
  period,
  className,
}) => {
  const price = period === MembershipPeriodOptions.Yearly ? yearlyPrice : monthlyPrice;
  const link = period === MembershipPeriodOptions.Yearly ? annualCtaLink : monthlyCtaLink;

  return (
    <div
      className={cn(
        className,
        ' px-3.5 py-7 flex flex-col relative rounded-3xl border border-[#DCD6E2] text-xs text-center shadow-[0_16px_32px_0_rgba(96,83,79,0.18)]'
      )}
    >
      {badge && (
        <span className="w-[146px] h-[34px] block absolute top-[-17px] right-0 left-0 mx-auto rounded-full bg-eggplant font-bold text-center text-sm text-[#E9DEFF] leading-[34px]">
          {badge}
        </span>
      )}
      {heading && <h3 className="text-purple text-2xl leading-[39.6px] font-bold mb-2">{heading}</h3>}
      {price && <h4 className="text-eggplant text-[24px] leading-[26.4px] mb-5">{price}</h4>}
      {benefitsTitle && <p className="font-bold mb-2">{benefitsTitle}</p>}
      {benefitsList && (
        <Text
          asElement="div"
          html={benefitsList}
          className={cn(style.benefits, 'flex flex-col pl-3 text-left text-xs')}
        />
      )}
      <div className="mt-auto">
        {additionalOfferImage && (
          <div className="flex flex-row items-center mt-6 mb-3 bg-[#EBF6F9] rounded-md">
            {renderImage(
              { url: additionalOfferImage.url, alt: additionalOfferImage.alt },
              { width: 70, height: 70, loading: 'lazy', className: 'inline mr-1' }
            )}
            <Text
              asElement="span"
              html={additionalOfferText}
              className={cn(style.additionalOffer, 'text-xs text-left')}
            />
          </div>
        )}

        {link && ctaText && (
          <Button variant="cta" href={link.toString()}>
            {ctaText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(PlanCard);
