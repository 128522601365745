import { faCircle, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { FC, useMemo, useState } from 'react';

import { getSubscriptionInfoFromSKU } from '@components/cart/helpers';
import { ModulePlan } from '@components/common/types/ModulePlan';
import { ModuelPlanFeatureValue, ModulePlanFeature } from '@components/common/types/ModulePlanFeature';
import { PremiumSource, ProductBrand } from '@components/product/enums';
import Button from '@components/ui/Button/Button';
import Container from '@components/ui/Container';
import Text from '@components/ui/Text/Text';
import Tooltip from '@components/ui/Tooltip/Tooltip';
import { useCustomer } from '@framework/customer';
import { AddToCartFn } from '@lib/hooks/useAddToCart';
import { useCanAddL360Premium } from '@lib/hooks/useCanAddL360Premium';
import { useHasL360PremiumInCart } from '@lib/hooks/useHasL360PremiumInCart';
import { useHasSubscriptionInCart } from '@lib/hooks/useHasSubscriptionInCart';
import { isEmeaLocale } from '@lib/locales';
import { webAppLink } from '@lib/webapp-links';

import plansStyle from '../plans.module.scss';
import s from './ComparePlans.module.scss';

interface Props {
  title: string;
  plans: ModulePlan[];
  planFeatures: ModulePlanFeature[];
  onAddToCart: AddToCartFn;
  loading: boolean;
}

const AddSusbcription = ({
  loading: disabled,
  onAddToCart,
  id,
  sku,
  cta: initialCta,
  name,
  disablePremium,
}: Pick<Props, 'loading' | 'onAddToCart'> & {
  cta: string;
  id: number;
  sku: string;
  name: string;
  disablePremium: boolean;
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { locale = i18n.defaultLocale } = router;
  const { t } = useTranslation(['common', 'plans']);

  const canAddLife360Premium = useCanAddL360Premium();
  const isLife360Plan = getSubscriptionInfoFromSKU(sku, locale)?.brand === ProductBrand.L360;
  const shouldDisableL360Cta = isLife360Plan && !canAddLife360Premium;

  const renderCTA = !isEmeaLocale(locale);
  const addToCart = async () => {
    if (isLife360Plan && process.env.NEXT_PUBLIC_APP_LIFE360_BASE_URL) {
      const link = webAppLink(process.env.NEXT_PUBLIC_APP_LIFE360_BASE_URL, undefined, name);
      window.location.href = link;
    } else if (id && sku) {
      setLoading(true);
      await onAddToCart(id.toString(), sku, name, undefined, PremiumSource.PREMIUM_PAGE);
      setLoading(false);
    }
  };

  const cta = shouldDisableL360Cta ? t('plans:choosePlans.warning.unableToAddLife360_short') : initialCta;

  return id && renderCTA ? (
    <Button
      className={s.button}
      variant="cta"
      onClick={() => addToCart()}
      aria-label={cta}
      disabled={!id || (disabled && !loading) || disablePremium || shouldDisableL360Cta}
      loading={loading}
      data-cy={`comparePlansTable-${camelCase(name)}-button`}
    >
      {cta}
    </Button>
  ) : null;
};

const ComparePlans: FC<Props> = ({ title, plans, planFeatures, onAddToCart, loading }) => {
  const { t } = useTranslation(['common', 'plans']);

  const { isSubscriber } = useCustomer();

  const hasSubscriptionInCart = useHasSubscriptionInCart();
  const hasL360PremiumInCart = useHasL360PremiumInCart();
  const disablePremium = useMemo(
    () => !!isSubscriber || hasSubscriptionInCart || hasL360PremiumInCart,
    [isSubscriber, hasSubscriptionInCart, hasL360PremiumInCart]
  );

  const getContent = (planValue: ModuelPlanFeatureValue) => {
    const defaultValue = (
      <FontAwesomeIcon icon={faTimes} className="text-accents-10" title={t('common:notAvailable')} />
    );

    // assume the default value is that the feature is not included
    if (!planValue) {
      return defaultValue;
    }

    // if there's a text value - render that
    if (planValue.textValue) {
      return (
        <Text variant="text-3" color="var(--text-secondary-2)" weight="bold">
          {planValue.textValue}
        </Text>
      );
    }

    // otherwise check if the feature is included and return the appropriate value
    if (planValue.isIncluded) {
      return <FontAwesomeIcon icon={faCircle} className="text-accents-10" title={t('common:available')} />;
    }

    return defaultValue;
  };

  const getCtaMessage = (defaultCta: string): string => {
    if (isSubscriber) {
      return t('plans:choosePlans.warning.alreadySubscribed_short');
    }
    if (hasSubscriptionInCart || hasL360PremiumInCart) {
      return t('plans:choosePlans.warning.alreadyInCart_short');
    }
    return defaultCta;
  };

  const renderHeader = () => (
    <thead>
      <tr>
        <th className=" p-3" />
        {plans.map(({ name }) => (
          <th key={name} className=" p-3">
            <Text variant="text-2" className={s.planName} asElement="h3">
              {name}
            </Text>
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderBody = () => {
    return (
      <>
        <tbody>
          {planFeatures.map(({ name, description, planValues }) => (
            <tr key={name} className="w-full">
              <td
                className={cn(s.borderedCell, `px-2  !border-l-0`)}
                style={{ width: `${100 / (planValues.length + 1)}%` }}
              >
                <Text variant="text-3" className={s.featureName}>
                  {name}

                  <Tooltip
                    tooltipContent={
                      description ? <Text color="var(--white)" weight="semibold" html={description} /> : ''
                    }
                    className="ml-2 cursor-pointer"
                    contentClassName={s.tooltipContent}
                    arrowClassName={s.arrowClassName}
                    placement="right"
                  >
                    <FontAwesomeIcon icon={faInfoCircle} aria-label={name} title={name} />
                  </Tooltip>
                </Text>
              </td>
              {planValues?.map((planValue) => (
                <td
                  key={planValue.planId}
                  className={cn(s.borderedCell, `text-center `)}
                  style={{ width: `${100 / (planValues.length + 1)}%` }}
                >
                  <div>{getContent(planValue)}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </>
    );
  };

  const renderFooter = () => (
    <>
      <tfoot className="hidden md:table-footer-group">
        <tr>
          <td />
          {plans.map(({ yearlyProduct, getPlanCta }) => {
            const { bigCommerceId = '', sku, name } = yearlyProduct || {};
            return (
              <td key={name} className="p-3 text-center">
                {bigCommerceId && getPlanCta && (
                  <AddSusbcription
                    onAddToCart={onAddToCart}
                    loading={loading}
                    cta={getCtaMessage(getPlanCta)}
                    id={bigCommerceId}
                    sku={sku}
                    name={name}
                    disablePremium={disablePremium}
                  />
                )}
              </td>
            );
          })}
        </tr>
      </tfoot>
    </>
  );

  const renderMobileFooter = () => (
    <div className="flex justify-end">
      {plans.map(({ yearlyProduct, getPlanCta }) => {
        const { bigCommerceId = '', sku, name } = yearlyProduct || {};
        return (
          <div key={name} className={s.mobileFooter}>
            {bigCommerceId && getPlanCta && (
              <AddSusbcription
                onAddToCart={onAddToCart}
                loading={loading}
                cta={getCtaMessage(getPlanCta)}
                id={bigCommerceId}
                sku={sku}
                name={name}
                disablePremium={disablePremium}
              />
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <Container>
      <Container className={cn(plansStyle.container, 'md:max-w-7xl max-w-[90%]')}>
        <Text variant="heading-2" className={plansStyle.heading}>
          {title}
        </Text>
        <div className="overflow-auto relative md:static">
          <table className="w-full border-collapse">
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
          </table>
        </div>
        {renderMobileFooter()}
      </Container>
    </Container>
  );
};

export default ComparePlans;
