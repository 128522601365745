import { Entry } from '@services/contentful/types';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { ModuleFlickableProduct } from '@components/common/types/ModuleFlickableProduct';
import { ContentModule } from '@components/common/types/Page';
import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Container from '@components/ui/Container';
import Text from '@components/ui/Text/Text';

import ProductTeaserCard from './ProductTeaserCard';

interface Props extends ContentModule {
  moduleMainProduct?: ModuleFlickableProduct;
  moduleSecondaryProducts: ModuleFlickableProduct[];
}

interface RendererProps extends ContentModule {
  moduleMainProduct: Entry<ModuleFlickableProduct>;
  moduleSecondaryProducts: Entry<ModuleFlickableProduct>[];
}

const ProductTeaser: FC<Props> = ({ moduleMainProduct, moduleSecondaryProducts }) => {
  const { t } = useTranslation(['common']);

  return (
    <Container>
      <div className="py-2 md:py-4 grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4">
        <Text variant="heading-2" className="sr-only">
          {t('home:featured.products')}
        </Text>
        {moduleMainProduct?.product && <ProductTeaserCard product={moduleMainProduct} isFeaturedProduct />}
        {moduleSecondaryProducts
          ?.filter((p) => p.product)
          .map((p) => <ProductTeaserCard key={p.product.id} product={p} />)}
      </div>
    </Container>
  );
};

const mapper = ({ moduleMainProduct, moduleSecondaryProducts, ...rest }: RendererProps): Props => ({
  ...rest,
  moduleMainProduct: mapContent(moduleMainProduct),
  moduleSecondaryProducts: mapContent(moduleSecondaryProducts),
});

export default Renderer({ name: 'moduleProductTeaser', mapper })(ProductTeaser);
