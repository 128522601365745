import cn from 'classnames';
import { FC } from 'react';

import { Image, Video } from '@commerce/types/common';
import { Renderer } from '@components/screen/factory';
import Button from '@components/ui/Button/Button';
import Container from '@components/ui/Container';
import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';
import Text from '@components/ui/Text/Text';
import { FontSizes, FontTypes } from '@components/ui/types/ContentfulTextOption';
import { renderImage } from '@lib/image';
import { isVideo } from '@lib/video';

import style from './ProductOverview.module.scss';

interface Perks {
  content: {
    internalName: string;
    title: string;
    description: string;
    icon: string;
  };
}

interface ModuleOverview {
  title?: string;
  eyebrow?: string;
  media: Image | Video;
  description?: string;
  subtitle: string;
  perks?: Perks[];
  videoThumbnail?: Image;
  titleFontSize?: FontSizes;
  titleFontType?: FontTypes;
  ctaUrl?: string;
  ctaText?: string;
  couponCode?: string;
  couponDescription?: string;
}

const iconUrl: Record<string, string> = {
  battery: '/svgs/overview/battery.svg',
  dishwasher: '/svgs/overview/dishwasher_icon.svg',
  mobile: '/svgs/overview/mobile.svg',
  qr: '/svgs/overview/qr_icon.svg',
  range: '/svgs/overview/range_icon.svg',
  water: '/svgs/overview/water_icon.svg',
  quotes: '/svgs/overview/quotes_icon.svg',
  antitheft: '/svgs/overview/antitheft_icon.svg',
  noaccesories: '/svgs/overview/noaccesories_icon.svg',
  reimbursement: '/svgs/overview/reimbursement_icon.svg',
  bell: '/images/overview/bell-icon.png',
  chart: '/images/overview/chart-icon.png',
  crash: '/images/overview/icon-large-crash-min.png',
  credit: '/images/overview/icon-large-credit-min.png',
  crime: '/images/overview/icon-large-crime-min.png',
  dba: '/images/overview/icon-large-dba-min.png',
  disaster: '/images/overview/icon-large-disaster-min.png',
  dispatch: '/images/overview/icon-large-dispatch-min.png',
  driver: '/images/overview/icon-large-drivingsummary-min.png',
  history: '/images/overview/icon-large-history-min.png',
  identity: '/images/overview/icon-large-identity-min.png',
  medical: '/images/overview/icon-large-medical-min.png',
  roadside: '/images/overview/icon-large-roadside-min.png',
  sos: '/images/overview/icon-large-sos-min.png',
  stolenphone: '/images/overview/icon-large-stolen-phone-min.png',
  stolenfunds: '/images/overview/icon-large-stolenfunds-min.png',
  travel: '/images/overview/icon-large-travel-min.png',
};

const Icon = ({ icon, description }: { icon: string; description: string }) =>
  iconUrl[icon] ? (
    <div className={style.icon}>
      {renderImage({ url: iconUrl[icon], alt: description || '' }, { 'aria-hidden': true, loading: 'lazy' })}
    </div>
  ) : null;

const SingleProductSection: FC<Pick<ModuleOverview, 'subtitle' | 'perks'>> = ({
  subtitle: sectionTitle,
  perks: attributes,
}) => (
  <div>
    <Text color="var(--black)" variant="base-bold" className="uppercase mb-8">
      {sectionTitle}
    </Text>
    <div className="flex flex-col flex-wrap justify-center gap-4 md:flex-row md:gap-8 lg:flex-nowrap lg:gap-3">
      {attributes?.map(({ content: { title, description, icon } }) => {
        return (
          <div key={title} className={style.card}>
            <Icon icon={icon} description={description} />
            <Text color="var(--black)" variant="header-5-book" asElement="p">
              {title}
            </Text>
            <Text color="var(--gray-50)" variant="base" asElement="p">
              {description}
            </Text>
          </div>
        );
      })}
    </div>
  </div>
);

const ProductOverview: FC<ModuleOverview> = ({
  title,
  titleFontSize = FontSizes.L,
  titleFontType = FontTypes.Normal,
  eyebrow,
  media,
  description,
  subtitle,
  perks,
  videoThumbnail,
  ctaText,
  ctaUrl,
  couponCode,
  couponDescription,
}) => {
  return (
    <div className={style.container}>
      <Container>
        <div className="px-4 py-16 md:px-16">
          {(eyebrow || title || media || description) && (
            <div className="mb-10">
              {eyebrow && (
                <Text color="var(--purple)" variant="eyebrow" className="uppercase" asElement="p">
                  {eyebrow}
                </Text>
              )}
              {title && (
                <Text
                  color="var(--black)"
                  variant="header-1"
                  className={cn(style.subheader, style[titleFontSize], style[titleFontType], { 'mt-2': eyebrow })}
                  asElement="h2"
                >
                  {title}
                </Text>
              )}
              {media && (
                <ImageOrVideo
                  className={style.media}
                  type={isVideo(media.type) ? media.type! : 'image'}
                  {...media}
                  thumbnail={videoThumbnail?.url || undefined}
                  width={664}
                />
              )}
              {description && (
                <Text variant="header-5-book" className="text-center" asElement="p">
                  {description}
                </Text>
              )}

              {/* COUPON SECTION */}
              {couponCode && (
                <div className="flex flex-col items-center justify-center">
                  {couponDescription && (
                    <Text
                      variant="base"
                      className="max-w-[447px] w-full sm:w-[90%] mx-auto text-center mt-10 mb-8"
                      asElement="p"
                    >
                      {couponDescription}
                    </Text>
                  )}
                  <div className={cn(style.couponContainer, 'p-2')}>
                    <Text variant="header-4" className="text-center uppercase" asElement="p" color="var(--purple)">
                      {couponCode}
                    </Text>
                  </div>
                </div>
              )}
              {ctaText && ctaUrl && (
                <Button variant="cta" href={ctaUrl} className="mt-10">
                  {ctaText}
                </Button>
              )}
            </div>
          )}
          <SingleProductSection subtitle={subtitle} perks={perks} />
        </div>
      </Container>
    </div>
  );
};

export default Renderer({ name: 'moduleOverview' })(ProductOverview);
